<template>
  <v-container class="px-0" fluid>
    <v-checkbox
      v-model="inputVal"
      :label="label"
    ></v-checkbox>
  </v-container>
</template>

<script>
export default {
  components: {
    //
  },
  props: ["value","label"],
  data: () => ({
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    //
  },
  mounted() {
  
  },
  methods: {
    //
  },
};
</script>